<template>
    <Layout>
        <div class="d-flex justify-content-between align-items-center">
            <h4 class="mb-0 font-size-18">{{ $t(`skipped bets`) }}</h4>
        </div>
        <div
            class="card data"
            style="border-top-left-radius: 0; border-top-right-radius: 0">
            <div class="card-header bg-light bg-soft filters">
                <b-form inline>
                    <table class="table table-bordered table-nowrap mb-0">
                        <tbody>
                            <tr>
                                <td class="text-center">
                                    {{ $t('date') }}
                                </td>

                                <td class="bg-white">
                                    <div class="d-flex gap-1 w-100">
                                        <datepicker
                                            v-model="filters.from"
                                            :first-day-of-week="0"
                                            :locale="$i18n.locale=='ko'?koLocale:null"
                                            confirm
                                            class="form-control bg-white"></datepicker>

                                        <label class="input-group-text"
                                            >~</label
                                        >
                                        <datepicker
                                            v-model="filters.to"
                                            :first-day-of-week="0"
                                            :locale="$i18n.locale=='ko'?koLocale:null"
                                            confirm
                                            class="form-control bg-white"></datepicker>
                                        <button
                                            class="btn btn-outline-info"
                                            @click="setDay('today')">
                                            {{ $t('today') }}
                                        </button>
                                        <button
                                            class="btn btn-outline-success"
                                            @click="setDay('yesterday')">
                                            {{ $t('yesterday') }}
                                        </button>
                                    </div>
                                </td>
                                <td class="text-center">
                                    {{ $t('keyword') }}
                                </td>
                                <td class="bg-white">
                                    <input
                                        type="text"
                                        class="form-control"
                                        :placeholder="$t(`enter keyword`)"
                                        v-model="filters.keyword" />
                                </td>
                                <td class="bg-white text-center">
                                    <div class="button-items">
                                        <button
                                            :class="`btn btn-danger ${
                                                loading ? 'disabled' : ''
                                            }`"
                                            @click="resetFilter()">
                                            {{ $t('reset') }}
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </b-form>
            </div>
            <div class="card-body pt-1">
                <multiselect
                    style="width: 120px"
                    class="multiselect-sm me-0"
                    :placeholder="`${$t('limit')}`"
                    v-model="filters.limit"
                    :options="[50, 100, 500, 1000]"
                    :preselect-first="true"
                    :can-deselect="false"
                    :can-clear="false">
                    <template v-slot:singlelabel="{ value }">
                        <span class="mx-auto">{{
                            $t('view') + ' ' + value.value
                        }}</span>
                    </template>
                </multiselect>
                <div ref="tableScroller" class="table-responsive">
                    <LoadingTable
                        :cols="7"
                        :rows="50"
                        class="table-bordered table-striped"
                        :td_small_class="`d-none`"
                        v-if="loading" />
                    <table
                        v-else
                        class="table table-bordered table-hover table-striped table-nowrap mb-0">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">
                                    {{ $t('date') }}
                                </th>
                                <th scope="col">
                                    {{ $t('product') }}
                                </th>
                                <th scope="col">
                                    {{ $t('game name') }}
                                </th>
                                <th scope="col">
                                    {{ $t('username') }}
                                </th>
                                <th scope="col">
                                    {{ $t('game id') }}
                                </th>
                                <th scope="col">
                                    {{ $t('bet id') }}
                                </th>
                                <th scope="col">
                                    {{ $t('bet amount') }}
                                </th>
                                <th scope="col">
                                    {{ $t('result time') }}
                                </th>
                                <th scope="col">
                                    {{ $t('payment amount') }}
                                </th>
                                <th scope="col">
                                    {{ $t('difference') }}
                                </th>
                                <th scope="col">
                                    {{ $t('balance') }}
                                </th>
                                <th scope="col">
                                    {{ $t('player points') }}
                                </th>
                                <th scope="col">
                                    {{ $t('agent commission') }}
                                </th>
                                <th scope="col">
                                    {{ $t('status') }}
                                </th>
                                <th scope="col">
                                    {{ $t('details') }}
                                </th>
                                <!-- <th scope="col">
                                            {{ $t('asset log') }}
                                        </th> -->
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(r, i) in data.list" :key="r._id">
                                <td class="text-center">
                                    {{
                                        i +
                                        1 +
                                        (data.current_page - 1) * data.per_page
                                    }}
                                </td>
                                <td class="text-center">
                                    {{ dateYmD(r.created_at, true) }}
                                </td>
                                <td class="text-center">
                                    {{ r.vendor }}
                                </td>
                                <td class="text-center">
                                    {{ r.title }}
                                </td>
                                <td class="text-start">
                                    <div class="td-username">
                                        <a
                                            href="javascript:void(0)"
                                            @click="
                                                goToTicketForm(
                                                    r.user.username,
                                                    'player'
                                                )
                                            ">
                                            <i
                                                class="bx bx-envelope bx-tada-hover fs-4"></i
                                        ></a>
                                        <a
                                            :title="$t(`click to view`)"
                                            href="javascript:void(0)"
                                            @click="
                                                goToUserForm(
                                                    r.user_id,
                                                    'player'
                                                )
                                            "
                                            class="link-username"
                                            ><i
                                                :class="`mdi mdi-numeric-${r.user.player.level}-box level-${r.user.player.level} fs-5`"></i>
                                            {{ r.user.username }}</a
                                        >
                                    </div>
                                </td>
                                <td>
                                    {{ r.reference_debit.username }}
                                </td>
                                <td>
                                    {{ r.reference_debit.transaction.id }}
                                </td>
                                <td class="text-end">
                                    {{ numberString(r.amount) }}
                                </td>
                                <td>
                                    {{ dateYmD(r.created_at, true) }}
                                </td>
                                <td class="text-end text-danger">
                                    {{
                                        r.win_amount && r.status != 'cancel'
                                            ? numberString(r.win_amount)
                                            : ''
                                    }}
                                </td>
                                <td class="text-end">
                                    {{ numberString(r.difference) }}
                                </td>
                                <td class="text-end">
                                    {{
                                        r.player_asset_log
                                            ? numberString(
                                                  r.player_asset_log
                                                      .updated_value
                                              )
                                            : ''
                                    }}
                                </td>
                                <td class="text-end">
                                    {{
                                        r.player_claim_rebate_amount
                                            ? `${numberStringDecimal(
                                                  r.player_claim_rebate_amount
                                              )}`
                                            : ''
                                    }}
                                    {{
                                        r.player_claim_commission_amount
                                            ? `(${numberStringDecimal(
                                                  r.player_claim_commission_amount
                                              )})`
                                            : ''
                                    }}
                                </td>
                                <td class="text-end">
                                    {{
                                        r.agent_claim_commission_amount
                                            ? `${numberStringDecimal(
                                                  r.agent_claim_commission_amount
                                              )}`
                                            : ''
                                    }}
                                </td>
                                <td class="text-center">
                                    <span
                                        :class="{
                                            'text-primary':
                                                r.status == 'pending',
                                            'text-danger': r.status == 'win',
                                            'text-info': r.status == 'lose',
                                            'text-secondary':
                                                r.status == 'cancel',
                                        }"
                                        >{{ $t(r.status) }}</span
                                    >
                                </td>
                                <td class="text-center">
                                    <button
                                        :disabled="view_loading"
                                        class="btn btn-outline-info btn-sm"
                                        @click="showViewModal(r._id)">
                                        {{ $t('view') }}
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div
                        class="no-data-placeholder"
                        v-if="!data.list.length && !loading">
                        {{ $t('no data found') }}
                    </div>
                    <a
                        href="javascript:void(0);"
                        class="scroll-pad left"
                        @mousedown="startScrolling('left')"
                        @mouseup="endScrolling"
                        @mouseleave="endScrolling"
                        ><i class="bx bx-caret-left"></i
                    ></a>
                    <!-- @click="scroll('right')" -->
                    <a
                        href="javascript:void(0);"
                        class="scroll-pad right"
                        @mousedown="startScrolling('right')"
                        @mouseup="endScrolling"
                        @mouseleave="endScrolling"
                        ><i class="bx bx-caret-right"></i
                    ></a>
                </div>
                <Pagination v-if="!loading" :data="data" @emitPage="initList" />
            </div>
        </div>
        <b-modal
            v-model="view_modal"
            @hidden="reset()"
            centered
            :title="$t(`details`)"
            title-class="font-18"
            body-class="pt-0"
            size="xl"
            hide-footer>
            <div class="loader-wrapper" v-if="view_loading">
                <b-spinner variant="dark"></b-spinner>
                <p class="fw-bold fs-5">{{ $t('loading') }}...</p>
            </div>
            <div class="bet-details-header">
                <div :class="`flex-fill`">
                    <table class="table table-sm table-bordered mb-0">
                        <tbody>
                            <tr>
                                <th class="bg-light bg-soft" style="width: 15%">
                                    {{ $t('date') }}
                                </th>
                                <td style="width: 35%">
                                    {{ dateYmD(bet_detail.created_at, true) }}
                                </td>
                                <th class="bg-light bg-soft" style="width: 15%">
                                    {{ $t('status') }}
                                </th>
                                <td style="width: 35%">
                                    <span
                                        :class="{
                                            'text-primary':
                                                bet_detail.status == 'pending',
                                            'text-danger':
                                                bet_detail.status == 'win',
                                            'text-info':
                                                bet_detail.status == 'lose',
                                            'text-secondary':
                                                bet_detail.status == 'cancel',
                                        }"
                                        >{{ $t(bet_detail.status) }}</span
                                    >
                                </td>
                            </tr>
                            <tr>
                                <th class="bg-light bg-soft" style="width: 15%">
                                    {{ $t('username') }}
                                </th>
                                <td style="width: 35%">
                                    {{ bet_detail.username }}
                                </td>
                                <th class="bg-light bg-soft" style="width: 15%">
                                    {{ $t('amount') }}
                                </th>
                                <td style="width: 35%">
                                    {{ numberString(bet_detail.amount) }}
                                </td>
                            </tr>
                            <tr>
                                <th class="bg-light bg-soft" style="width: 15%">
                                    {{ $t('game') }}
                                </th>
                                <td style="width: 35%">
                                    {{ $t(bet_detail.game) }}
                                </td>
                                <th class="bg-light bg-soft" style="width: 15%">
                                    {{ $t('payment amount') }}
                                </th>
                                <td style="width: 35%">
                                    {{ numberString(bet_detail.win_amount) }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="baccarat-cards" v-if="bet_detail.card_info">
                    <span
                        :class="`tie-indicator ${
                            bet_detail.card_info.outcome == 'Tie'
                                ? 'result'
                                : ''
                        }`"
                        >{{ $t('tie') }}</span
                    >
                    <div
                        :class="`text-start d-flex align-items-center gap-1 player ${
                            bet_detail.card_info.outcome == 'Player'
                                ? 'result'
                                : ''
                        }`">
                        <div class="side">
                            {{ $t('player') }}
                        </div>
                        <div class="cards">
                            <img
                                v-for="(c, i) in bet_detail.card_info.player
                                    .cards"
                                :key="i"
                                :src="require(`@/assets/images/cards/${c}.png`)"
                                :alt="`${c}.png`" />
                        </div>
                        <div class="score">
                            {{ bet_detail.card_info.player.score }}
                        </div>
                    </div>
                    <div
                        :class="`text-start d-flex align-items-center gap-1 banker ${
                            bet_detail.card_info.outcome == 'Banker'
                                ? 'result'
                                : ''
                        }`">
                        <div class="score">
                            {{ bet_detail.card_info.banker.score }}
                        </div>
                        <div class="cards">
                            <img
                                v-for="(c, i) in bet_detail.card_info.banker
                                    .cards"
                                :key="i"
                                :src="require(`@/assets/images/cards/${c}.png`)"
                                :alt="`${c}.png`" />
                        </div>
                        <div class="side">
                            {{ $t('banker') }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="table-responsive" >
                <table
                    class="table table-bordered table-striped table-nowrap table-sm mb-0">
                    <thead v-show="bet_detail.agent_commissions.length">
                        <tr class="bg-light bg-soft fs-5">
                            <th colspan="6" class="text-start">
                                {{ $t('agent commission') }}
                            </th>
                        </tr>
                        <tr>
                            <th>
                                {{ $t('username') }}
                            </th>
                            <th>
                                {{ $t('percentage') }}
                            </th>
                            <th>
                                {{ $t('initial value') }}
                            </th>
                            <th>
                                {{ $t('amount') }}
                            </th>
                            <th>
                                {{ $t('updated value') }}
                            </th>
                            <th>
                                {{ $t('note') }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="p in bet_detail.agent_commissions"
                            :key="p._id">
                            <td>
                                {{ p.user ? p.user.username : '' }}
                            </td>
                            <td class="text-end text-success">
                                {{
                                    numberStringDecimal(
                                        p.commission_percentage
                                    )
                                }}%
                            </td>
                            <td class="text-end">
                                {{ numberStringDecimal(p.initial_value) }}
                            </td>
                            <td class="text-end fw-medium">
                                {{ numberStringDecimal(p.amount) }}
                            </td>
                            <td class="text-end">
                                {{ numberStringDecimal(p.updated_value) }}
                            </td>
                            <td class="text-start text-muted">
                                {{ p.note }}
                            </td>
                        </tr>
                    </tbody>
                    <thead v-show="bet_detail.player_commissions.length">
                        <tr class="bg-light bg-soft fs-5">
                            <th colspan="6" class="text-start">
                                {{ $t('player commission') }}
                            </th>
                        </tr>
                        <tr>
                            <th>
                                {{ $t('username') }}
                            </th>
                            <th>
                                {{ $t('percentage') }}
                            </th>
                            <th>
                                {{ $t('initial value') }}
                            </th>
                            <th>
                                {{ $t('amount') }}
                            </th>
                            <th>
                                {{ $t('updated value') }}
                            </th>
                            <th>
                                {{ $t('note') }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="p in bet_detail.player_commissions"
                            :key="p._id">
                            <td>
                                {{ p.user ? p.user.username : '' }}
                            </td>
                            <td class="text-end text-success">
                                {{
                                    numberStringDecimal(
                                        p.commission_percentage
                                    )
                                }}%
                            </td>
                            <td class="text-end">
                                {{ numberStringDecimal(p.initial_value) }}
                            </td>
                            <td class="text-end fw-medium">
                                {{ numberStringDecimal(p.amount) }}
                            </td>
                            <td class="text-end">
                                {{ numberStringDecimal(p.updated_value) }}
                            </td>
                            <td class="text-start text-muted">
                                {{ p.note }}
                            </td>
                        </tr>
                    </tbody>
                    <thead v-show="bet_detail.player_rebate.length">
                        <tr class="bg-light bg-soft fs-5">
                            <th colspan="6" class="text-start">
                                {{ $t('player rebate') }}
                            </th>
                        </tr>
                        <tr>
                            <th>
                                {{ $t('username') }}
                            </th>
                            <th>
                                {{ $t('percentage') }}
                            </th>
                            <th>
                                {{ $t('initial value') }}
                            </th>
                            <th>
                                {{ $t('amount') }}
                            </th>
                            <th>
                                {{ $t('updated value') }}
                            </th>
                            <th>
                                {{ $t('note') }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="p in bet_detail.player_rebate" :key="p._id">
                            <td>
                                {{ p.user ? p.user.username : '' }}
                            </td>
                            <td class="text-end text-success">
                                {{
                                    numberStringDecimal(
                                        p.commission_percentage
                                    )
                                }}%
                            </td>
                            <td class="text-end">
                                {{ numberStringDecimal(p.initial_value) }}
                            </td>
                            <td class="text-end fw-medium">
                                {{ numberStringDecimal(p.amount) }}
                            </td>
                            <td class="text-end">
                                {{ numberStringDecimal(p.updated_value) }}
                            </td>
                            <td class="text-start text-muted">
                                {{ p.note }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </b-modal>
    </Layout>
</template>

<script>
import Datepicker from 'vue3-datepicker';
import Layout from '../../../layouts/main';
import PageHeader from '@/components/page-header';
import LoadingTable from '@/components/loading-table';
import Pagination from '../../../../components/pagination.vue';
import Multiselect from '@vueform/multiselect';
import Switches from 'vue-switches';
import { mapActions } from 'vuex';
import convert from '../../../../mixins/convert';
import datetime from '../../../../mixins/datetime';
import number from '../../../../mixins/number';
import ui from '../../../../mixins/ui';
export default {
    components: {
        Layout,
        PageHeader,
        Pagination,
        Switches,
        LoadingTable,
        Multiselect,
        Datepicker,
    },
    mixins: [datetime, number, convert, ui],
    data() {
        return {
            test: null,
            title: `players`,
            filters: {
                keyword: null,
                from: this.getOneYearAgo(),
                to: new Date(),
                limit: 50,
            },
            data: {
                list: [],
                links: [],
                current_page: 1,
                per_page: 1,
                last_page: 1,
                total: 0,
            },
            bet_detail: {
                _id: '',
                created_at: '',
                username: '',
                game: '',
                status: '',
                amount: '',
                win_amount: '',
                player_commissions: [],
                agent_commissions: [],
                player_rebate: [],
                card_info: null,
            },
            view_modal: false,
            view_loading: false,
            loading: false,
            hoverDivLeft: 0,
            hoverDivTop: 0,
        };
    },
    watch: {
        filters: {
            handler() {
                this.initList(1);
            },
            deep: true,
        },
    },
    methods: {
        ...mapActions('bet', {
            betGetList: 'getList',
            betGetListTotal: 'getListTotal',
            betUpdate: 'update',
            betView: 'view',
        }),
        async initList(p) {
            this.resetScroll();
            var pl = {
                user: 'player',
                limit: this.filters.limit,
                vendor: 'all',
                status: 'skipped',
                live: false,
                from: this.dateYmD(this.filters.from) + ' 00:00:00',
                to: this.dateYmD(this.filters.to) + ' 23:59:59',
                search: this.filters.keyword ? this.filters.keyword : '',
                page_no: p ? p : 1,
            };

            this.loading = true;
            const data = await this.betGetList(pl);
            this.data.list = data.data.data;
            this.data.links = data.data.links;
            this.data.current_page = data.data.current_page;
            this.data.per_page = data.data.per_page;
            this.data.last_page = data.data.last_page;
            this.data.total = data.data.total;
            this.loading = false;
        },
        resetFilter() {
            this.filters.from = new Date(this.firstDateOftheMonth());
            this.filters.to = new Date();
            this.filters.keyword = null;
        },
        showHoverDiv(event, id, index) {
            const hoverDiv = document.getElementById(`hover-div-${id}`);
            if (!hoverDiv) return;
            hoverDiv.style.display = 'block';
            this.hoverDivLeft = this.$refs.tableScroller.clientWidth / 2;
            this.hoverDivTop = event.pageY / 2;
        },
        hideHoverDiv(id) {
            const hoverDiv = document.getElementById(`hover-div-${id}`);
            if (!hoverDiv) return;
            hoverDiv.style.display = 'none';
        },
        async showViewModal(id) {
            if (this.view_loading) return;
            this.view_modal = true;
            this.view_loading = true;
            const res = await this.betView(id);
            if (res) {
                this.bet_detail._id = res._id;
                this.bet_detail.created_at = res.created_at;
                this.bet_detail.username = res.user.username;
                this.bet_detail.game = res.game;
                this.bet_detail.status = res.status;
                this.bet_detail.amount = res.amount;
                this.bet_detail.win_amount = res.win_amount;
                this.bet_detail.agent_commissions = res.agent_commissions;
                this.bet_detail.player_commissions = res.player_commissions;
                this.bet_detail.player_rebate = res.player_rebate;
            }
            this.view_loading = false;
        },
        reset() {
            this.bet_detail._id = '';
            this.bet_detail.created_at = '';
            this.bet_detail.username = '';
            this.bet_detail.game = '';
            this.bet_detail.status = '';
            this.bet_detail.amount = '';
            this.bet_detail.win_amount = '';
            this.bet_detail.agent_commissions = [];
            this.bet_detail.player_commissions = [];
            this.bet_detail.player_rebate = [];
            this.bet_detail.card_info = null;
        },
        setDay(mode) {
            if (mode == 'today') {
                this.filters.from = new Date();
                this.filters.to = new Date();
            } else {
                this.filters.from = this.addDaysToDate(-1);
                this.filters.to = this.addDaysToDate(-1);
            }
            this.initList(1);
        },
    },
    mounted() {
        this.initList(1);
    },
};
</script>
